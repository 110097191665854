// // import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressPipe } from './address.pipe';
import { ApiErrorPipe } from './api-error.pipe';
import { BudgetOsPipe } from './budget-os.pipe';
import { CashTypePipe } from './cash-type.pipe';
import { CashierHistoricClassPipe } from './cashier-historic-class.pipe';
import { CepPipe } from './cep.pipe';
// import { CESTPipe } from './cest.pipe';
import { CfopPipe } from './cfop.pipe';
import { ClientFilterPipe } from './client-filter.pipe';
// import { DateFormatPipe } from './date-format.pipe';
import { DeadlinePipe } from './deadline.pipe';
import { DisplayWithPipe } from './display-with.pipe';
import { DocumentTypePipe } from './document-type.pipe';
import { DocumentPipe } from './document.pipe';
import { EmailPipe } from './email.pipe';
import { ErrorPipe } from './error.pipe';
import { ErrorsOnFieldsPipe } from './errors-on-fields.pipe';
import { FilterAutocompletePipe } from './filter-autocomplete.pipe';
import { FilterPipe } from './filter.pipe';
import { FindPropertyByKeyPipe } from './find-property-by-key.pipe';
import { GenderPipe } from './gender.pipe';
import { GroupFilterPipe } from './group-filter.pipe';
import { ImporterStatusPipe } from './importer-status.pipe';
import { IndexInPipe } from './index-in.pipe';
import { InvoiceDevolutionedPipe } from './invoice-devolutioned.pipe';
import { InvoiceLaborIncludedPipe } from './invoice-labor-included.pipe';
import { InvoicePartIncludedPipe } from './invoice-part-included.pipe';
import { InvoicePropertyPipe } from './invoice-property.pipe';
import { InvoiceStatusPipe } from './invoice-status.pipe';
import { ItemModifiedPipe } from './item-modified.pipe';
import { LaborFilterPipe } from './labor-filter.pipe';
import { LaborInfoPipe } from './labor-info.pipe';
import { LaborStatePipe } from './labor-state.pipe';
import { LanguagePipe } from './language.pipe';
// import { MapToArrayPipe } from './map-to-array.pipe';
import { MaritalStatusPipe } from './marital-status.pipe';
import { MediaTypePipe } from './media-type.pipe';
import { MenuSelectedPipe } from './menu-selected.pipe';
import { MinPipe } from './min.pipe';
import { MoneyPipe } from './money.pipe';
import { OperationFilterPipe } from './operation-filter.pipe';
import { OsTabPipe } from './os-tab.pipe';
import { PartFilterPipe } from './part-filter.pipe';
import { PartInfoPipe } from './part-info.pipe';
import { PartStatePipe } from './part-state.pipe';
import { PaymentApplicationPipe } from './payment-application.pipe';
import { PaymentMethodPipe } from './payment-method.pipe';
import { PhonePipe } from './phone.pipe';
import { PreviewTitlesPipe } from './preview-titles.pipe';
import { RoLaborIncludedPipe } from './ro-labor-included.pipe';
import { RoPartIncludedPipe } from './ro-part-included.pipe';
import { RoStateGroupPipe } from './ro-state-group.pipe';
import { RoStatePipe } from './ro-state.pipe';
import { SafePipe } from './safe.pipe';
import { SliderConfigPipe } from './slider-config.pipe';
import { StatePipe } from './state.pipe';
import { ValueOfPercentagePipe } from './value-of-percentage.pipe';
import { AllEqualsPipe } from './all-equals.pipe';
import { ReduceSumPipe } from './reduce-sum.pipe';
import { KilometersPipe } from './kilometers.pipe';
import { OsCilindradasCalculatePipe } from './os-cilindradas-calculate.pipe';
import { FieldWithOutInfoPipe } from './field-with-out-info.pipe';
import { OperationTitlePipe } from './operation-title.pipe';
import { CanAccessPipe } from './can-access.pipe';


const pipes = [
  AddressPipe,
  // DateFormatPipe,
  MaritalStatusPipe,
  DocumentPipe,
  PhonePipe,
  EmailPipe,
  LanguagePipe,
  KilometersPipe,
  GenderPipe,
  StatePipe,
  FilterPipe,
  CashTypePipe,
  DocumentTypePipe,
  RoStateGroupPipe,
  PartStatePipe,
  PartFilterPipe,
  LaborFilterPipe,
  LaborStatePipe,
  ClientFilterPipe,
  GroupFilterPipe,
  CfopPipe,
  ErrorPipe,
  InvoiceStatusPipe,
  ItemModifiedPipe,
  BudgetOsPipe,
  ErrorsOnFieldsPipe,
  ValueOfPercentagePipe,
  OperationFilterPipe,
  CepPipe,
  DeadlinePipe,
  InvoiceDevolutionedPipe,
  MinPipe,
  RoPartIncludedPipe,
  RoLaborIncludedPipe,
  InvoicePartIncludedPipe,
  InvoiceLaborIncludedPipe,
  ImporterStatusPipe,
  MenuSelectedPipe,
  FindPropertyByKeyPipe,
  InvoicePropertyPipe,
  ApiErrorPipe,
  PartInfoPipe,
  LaborInfoPipe,
  PreviewTitlesPipe,
  PaymentMethodPipe,
  RoStatePipe,
  IndexInPipe,
  OsCilindradasCalculatePipe,
  OsTabPipe,
  FilterAutocompletePipe,
  MoneyPipe,
  CashierHistoricClassPipe,
  PaymentApplicationPipe,
  SafePipe,
  SliderConfigPipe,
  DisplayWithPipe,
  FieldWithOutInfoPipe,
  MediaTypePipe,
  AllEqualsPipe,
  ReduceSumPipe,
  OperationTitlePipe,
  CanAccessPipe
  // CESTPipe,
  // MapToArrayPipe
];

@NgModule({
  imports: [
    CommonModule,
    // NgxSliderModule
  ],
  declarations: [pipes],
  exports: [pipes],
  providers: [pipes]
})
export class PipesModule { }
